export const photos = [
  {
    original: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/1.%20Putih%201.jpg?updatedAt=1697691985467`,
    thumbnail: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/1.%20Putih%201.jpg?updatedAt=1697691985467`,
  },
  {
    original: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/2.%20Putih%202%20-%20edited%20DSC05759.jpg?updatedAt=1698039608125`,
    thumbnail: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/2.%20Putih%202%20-%20edited%20DSC05759.jpg?updatedAt=1698039608125`,
  },
  {
    original: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/3.%20Putih%203.jpg?updatedAt=1697691985555`,
    thumbnail: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/3.%20Putih%203.jpg?updatedAt=1697691985555`,
  },
  {
    original: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/4.%20Putih%204.jpg?updatedAt=1697691984749`,
    thumbnail: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/4.%20Putih%204.jpg?updatedAt=1697691984749`,
  },
  {
    original: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/5.%20Pink%201.jpg?updatedAt=1697691985238`,
    thumbnail: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/5.%20Pink%201.jpg?updatedAt=1697691985238`,
  },
  {
    original: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/6.%20Pink%202.jpg?updatedAt=1697691985443`,
    thumbnail: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/6.%20Pink%202.jpg?updatedAt=1697691985443`,
  },
  {
    original: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/7.%20Pink%203.jpg?updatedAt=1697691984770`,
    thumbnail: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/7.%20Pink%203.jpg?updatedAt=1697691984770`,
  },
  {
    original: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/8.%20Pink%204.jpg?updatedAt=1697691985462`,
    thumbnail: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/8.%20Pink%204.jpg?updatedAt=1697691985462`,
  },
  {
    original: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/9.%20Hitam%201.jpg?updatedAt=1697691985498`,
    thumbnail: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/9.%20Hitam%201.jpg?updatedAt=1697691985498`,
  },
  {
    original: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/10.%20Hitam%202.jpg?updatedAt=1697691985454`,
    thumbnail: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/10.%20Hitam%202.jpg?updatedAt=1697691985454`,
  },
  {
    original: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/11.%20Hitam%203.jpg?updatedAt=1697691988686`,
    thumbnail: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/11.%20Hitam%203.jpg?updatedAt=1697691988686`,
  },
  {
    original: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/12.%20Hitam%204.jpg?updatedAt=1697691988767`,
    thumbnail: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/12.%20Hitam%204.jpg?updatedAt=1697691988767`,
  },
  {
    original: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/13.%20Merah%201.jpg?updatedAt=1697691988957`,
    thumbnail: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/13.%20Merah%201.jpg?updatedAt=1697691988957`,
  },
  {
    original: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/14.%20Merah%202.jpg?updatedAt=1697691989062`,
    thumbnail: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/14.%20Merah%202.jpg?updatedAt=1697691989062`,
  },
  {
    original: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/15.%20Merah%203.jpg?updatedAt=1697691989036`,
    thumbnail: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/15.%20Merah%203.jpg?updatedAt=1697691989036`,
  },
  {
    original: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/16.%20Merah%204.jpg?updatedAt=1697691989447`,
    thumbnail: `https://ik.imagekit.io/drpq5xrph/karissaricky/gallery/16.%20Merah%204.jpg?updatedAt=1697691989447`,
  },
  // {
  //   original: P17,
  //   // thumbnail: P17,
  // },
  // {
  //   original: P18,
  //   // thumbnail: P18,
  // },
  // {
  //   original: P19,
  //   // thumbnail: P19,
  // },
  // {
  //   original: P20,
  //   // thumbnail: P20,
  // },
  // {
  //   original: P21,
  //   // thumbnail: P21,
  // },
  // {
  //   original: P22,
  //   // thumbnail: P22,
  // },
  // {
  //   original: P23,
  //   // thumbnail: P23,
  // },
  // {
  //   original: P24,
  //   // thumbnail: P24,
  // },
  // {
  //   original: P25,
  //   // thumbnail: P25,
  // },
  // {
  //   original: P26,
  //   // thumbnail: P26,
  // },
  // {
  //   original: P27,
  //   // thumbnail: P27,
  // },
  // {
  //   original: P28,
  //   // thumbnail: P28,
  // },
  // {
  //   original: P29,
  //   // thumbnail: P29,
  // },
  // {
  //   original: P30,
  //   // thumbnail: P30,
  // },
  // {
  //   original: P31,
  //   // thumbnail: P31,
  // },
  // {
  //   original: P32,
  //   // thumbnail: P32,
  // },
];
