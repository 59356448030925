import get from '@invitato/helpers/dist/getLocale';

export default {
  ayatName: get('Kolose 3:14 TB', '1 Corinthians 13:1'),
  ayatNameMuslim: get('Q.S. Ar-Rum: 21', 'Q.S. Ar-Rum: 21'),
  ayat: get(
    `Dan di atas semuanya itu: kenakanlah kasih, sebagai pengikat yang mempersatukan dan menyempurnakan.`,
    `Love bears all things, believes all things, hopes all things, endures all things. Love never fails.`,
  ),
  ayatMuslim: get(
    `Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan diantaramu rasa kasih dan sayang. Sungguh, pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.`,
    `And of His signs is that He created for you from yourselves mates that you may find tranquility in them, and He placed between you affection and mercy. Indeed in that are signs for a people who give thought.`,
  ),
  thanks: get(
    'TERIMAKASIH UNTUK SEMUA KATA, <br />DOA, DAN PERHATIAN YANG DIBERIKAN.',
    'THANK YOU FOR ALL THE WORDS, <br />PRAYERS, AND ATTENTION GIVEN',
  ),
  closingAnnouncement: get(
    'Menjadi sebuah kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i tetap berkenan memberikan doa restu secara online untuk pelaksanaan hari pernikahan kami. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan.',
    'It will be a joy for us if you are still willing to give your blessing from afar. Thank you for all the words, prayers, and attention given.',
  ),
  closingInvitation: get(
    'Menjadi sebuah kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dalam hari bahagia kami. Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan. Sampai jumpa di hari bahagia kami!',
    'It will be a joy for us if you are able to attend on our wedding day. Thank you for all the kind words, prayers, and attention given. See you on our wedding day!',
  ),
};
